<template>
    <div class="main-container">
      <h5 class="pageTitle">비행승인 수정/삭제</h5>
      <div class="form-container">
        <div class="form-group">
          <label for="model">모델(타입)</label>
          <input
                v-model="form.model"
                type="text"
                class="input-box"
                readonly
           />
          <!-- <select v-model="form.model_ID" class="select-box">
            <option v-for="model in droneModels" :key="model.uuid" :value="model.uuid">
              {{ model.model }}
            </option>
          </select> -->
        </div>
  
        <div class="form-group">
          <label for="name">비행승인명</label>
          <input v-model="form.name" type="text" class="input-box" />
        </div>
  
        <div class="form-group">
          <label for="expire_date">만료일</label>
          <input v-model="form.expire_date" type="date" class="input-box wide-input" />
        </div>
  
        <div class="form-group">
          <label for="smsYN">SMS 예약 여부</label>
          <select v-model="form.sms_YN" class="input-select">
            <option :value="true">예</option>
            <option :value="false">아니오</option>
          </select>
        </div>
  
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-update" @click="updateFlightAppv">수정</button>
          <button class="button-delete" @click="deleteFlightAppv">삭제</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import Swal from "sweetalert2";
  import DailyCheckModule from "@/lib/DailyCheckModule";

  export default {
    name: "FlightAppvUpdateDelete",
    data() {
      return {
        form: {
          uuid: "",
          model_ID: "",
          model:"",
          name: "",
          expire_date: "",
          sms_YN: true,
        },
        droneModels: [],
      };
    },
    computed:{
      senderNumber(){
        return this.$store.state.senderNumber;
      }
    },
    created() {
      this.fetchFlightAppvData();
      this.fetchDroneModels();
    },
    methods: {
      async fetchFlightAppvData() {
        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData ? tokenData.access_token : "";
          const { id } = this.$route.params;
  
          const response = await axios.get(`/flight-appv/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (response.data) {
            this.form = {
              uuid: response.data.uuid,
              model_ID: response.data.model_ID,
              model: response.data.model || "Unknown", 
              name: response.data.name,
              expire_date: response.data.expire_date.split("T")[0],
              sms_YN: response.data.sms_YN,
            };
          }
        } catch (error) {
          console.error("비행승인 데이터 로드 오류:", error);
        }
      },
      async fetchDroneModels() {
        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData ? tokenData.access_token : "";
  
          const response = await axios.get("/drones/type", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          this.droneModels = response.data;
        } catch (error) {
          console.error("드론 모델 로드 오류:", error);
        }
      },
      async updateFlightAppv() {
        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData ? tokenData.access_token : "";
  
          await axios.put(
            `/flight-appv/${this.form.uuid}`,
            {
              model_ID: this.form.model_ID,
              name: this.form.name,
              expire_date: new Date(`${this.form.expire_date}T23:59:59`),
              sms_YN: this.form.sms_YN,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
  
          Swal.fire("성공", "비행승인이 수정되었습니다.", "success");
          //sms데이터 재생성
          await DailyCheckModule.recreateSmsData(this.senderNumber);

          this.goToList();
        } catch (error) {
          console.error("수정 실패:", error);
          Swal.fire("오류", "수정 중 문제가 발생했습니다.", "error");
        }
      },
      async deleteFlightAppv() {
        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData ? tokenData.access_token : "";
  
          await axios.delete(`/flight-appv/${this.form.uuid}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          Swal.fire("성공", "비행승인이 삭제되었습니다.", "success");
          
          //sms데이터 재생성
          await DailyCheckModule.recreateSmsData(this.senderNumber);

          this.goToList();
        } catch (error) {
          console.error("삭제 실패:", error);
          Swal.fire("오류", "삭제 중 문제가 발생했습니다.", "error");
        }
      },
      goToList() {
        this.$router.push("/flightAppv-read");
      },
    },
  };
  </script>
  
  <style scoped>
  /* 동일한 스타일 유지 */
  .main-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    max-width: 800px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .input-box,
  .select-box {
    flex: 1;
    padding: 8px;
    margin-right: 40px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    max-width: 300px;
  }
  
  .input-select {
    width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .button-container {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
  }
  
  .button-list {
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-update {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-delete {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  </style>
  